import React from "react"
import { Container } from "../../container"
import icon1 from "./images/icon1.svg"
import icon2 from "./images/icon2.svg"
import styles from "./howItWork.module.css"
import styles2 from "./hero.module.css"
import styles3 from "../../hero3/hero3.module.css"
import { Button2 } from "../../button2"
import { openVoucher } from "./hero"
import cx from "classnames"

export const HowItWork = () => {
  return (
    <section className={styles.HowItWork}>
      <Container>
        <h3 className="fancyUnderlineText fancyUnderlineText--orange sectionTitleNew text-center">
          Wie es <span>funktioniert:</span>
        </h3>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <img src={icon1} alt="icon" />
            <p className={styles.itemText}>
              Gib den Gutschein einem Freund und er kann ihn für eine zwei- und
              mehrwöchige Bestellung anwenden.
            </p>
            <span>1</span>
          </li>
          <li className={styles.listItem}>
            <img src={icon2} alt="icon" />
            <p className={styles.itemText}>
              Nach der Geltendmachung des Gutscheins schreiben wir deinem Konto
              100 Euro für deine nächste Bestellung gut.
            </p>
            <span>2</span>
          </li>
        </ul>
        <Button2
          handleClick={openVoucher}
          className={cx(styles3.button, styles2.button)}
          style={{ margin: "60px 0" }}
          color="secondary"
        >
          <svg
            className={styles2.buttonIcon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_209)">
              <path
                d="M9.87798 18.122C10.1566 18.4008 10.4874 18.6219 10.8515 18.7728C11.2156 18.9237 11.6059 19.0014 12 19.0014C12.3941 19.0014 12.7844 18.9237 13.1485 18.7728C13.5126 18.6219 13.8434 18.4008 14.122 18.122L17.333 14.911C17.5051 14.7206 17.5975 14.4713 17.5909 14.2147C17.5843 13.958 17.4793 13.7138 17.2976 13.5325C17.1159 13.3511 16.8714 13.2466 16.6148 13.2406C16.3581 13.2346 16.109 13.3274 15.919 13.5L12.993 16.427L13 1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0V0C11.7348 0 11.4804 0.105357 11.2929 0.292893C11.1053 0.48043 11 0.734784 11 1L10.991 16.408L8.08098 13.5C7.89334 13.3125 7.6389 13.2072 7.37363 13.2073C7.10836 13.2074 6.85399 13.3129 6.66648 13.5005C6.47897 13.6881 6.37369 13.9426 6.37378 14.2079C6.37387 14.4731 6.47934 14.7275 6.66698 14.915L9.87798 18.122Z"
                fill="white"
              />
              <path
                d="M23 16C22.7348 16 22.4804 16.1054 22.2929 16.2929C22.1054 16.4804 22 16.7348 22 17V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V17C2 16.7348 1.89464 16.4804 1.70711 16.2929C1.51957 16.1054 1.26522 16 1 16C0.734784 16 0.48043 16.1054 0.292893 16.2929C0.105357 16.4804 0 16.7348 0 17L0 21C0 21.7957 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7957 24 21V17C24 16.7348 23.8946 16.4804 23.7071 16.2929C23.5196 16.1054 23.2652 16 23 16Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_209">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Gutschein herunterladen
        </Button2>
      </Container>
    </section>
  )
}
