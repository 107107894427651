import React from "react"
import { Hero } from "../../components/voucher/components/hero"
import { HowItWork } from "../../components/voucher/components/howItWork"

const VoucherPage = () => {
  return (
    <>
      <Hero />
      <HowItWork />
    </>
  )
}

export default VoucherPage
